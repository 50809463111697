import "./App.css";
import React from "react";
import { HmacSHA256 } from "crypto-js";
import Base64 from "crypto-js/enc-base64";
import Hex from "crypto-js/enc-hex";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import mqtt from "mqtt/dist/mqtt";
import "bootstrap/dist/css/bootstrap.min.css";

function parseURL() {
  const base64_args = document.location.hash.replace(/^#/, "");
  if (base64_args.length === 0) {
    console.log(
      window.btoa(
        JSON.stringify({
          u: "admin",
          s: "UJ7s3ycYe8Ha5ru1",
          d: "cl@ssv-service.de",
          n: "invite2",
        })
      )
    );
    throw new Error("Missing required args");
  }
  const args = JSON.parse(window.atob(base64_args));
  const user = args.u;
  const fixedDescription = args.f;
  const destination = args.d;
  const secret = Hex.parse(args.s);
  const nonce = Hex.parse(args.n);
  return { user, fixedDescription, destination, secret, nonce };
}

function CryptJsWordArrayToUint8Array(wordArray) {
  const l = wordArray.sigBytes;
  const words = wordArray.words;
  const result = new Uint8Array(l);
  var i = 0 /*dst*/,
    j = 0; /*src*/
  while (true) {
    // here i is a multiple of 4
    if (i === l) break;
    var w = words[j++];
    result[i++] = (w & 0xff000000) >>> 24;
    if (i === l) break;
    result[i++] = (w & 0x00ff0000) >>> 16;
    if (i === l) break;
    result[i++] = (w & 0x0000ff00) >>> 8;
    if (i === l) break;
    result[i++] = w & 0x000000ff;
  }
  return result;
}

function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function App() {
  try {
    const invite = parseURL();
    return (
      <div className="App">
        <header className="App-header">
          <p>
            You have been invited to register your FIDO device for the user{" "}
            {invite.user}
          </p>
          <FidoRegister
            username={invite.user}
	    fixedDescription={invite.fixedDescription}
            secret={invite.secret}
            receiver={invite.destination}
            nonce={invite.nonce}
          />
        </header>
      </div>
    );
  } catch (e) {
    return (
      <div className="App">
        <header className="App-header">
          <Alert variant="danger">Invalid invite link</Alert>
        </header>
      </div>
    );
  }
}

class FidoRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phase: "challenge",
      rp: "gw.ssv-service.de",
      description: "",
      encodedCredential: null,
      credential: null,
      challenge: this.hmacSign("challenge"),
    };
    this.fidoChallenge = this.fidoChallenge.bind(this);
  }
  hmacSign(path) {
    return HmacSHA256(path, this.props.secret);
  }
  fidoChallenge(e) {
    e.preventDefault();
    const props = this.props;
    const state = this.state;
    const publicKey = {
      rp: { name: "SSV Service", id: state.rp },
      user: {
        id: new Uint8Array(16),
        name: props.username,
        displayName: props.username,
      },
      authenticatorSelection: { 
	      userVerification: "discouraged",
	      requireResidentKey: false,
      },
      challenge: CryptJsWordArrayToUint8Array(state.challenge),
      pubKeyCredParams: [
        {
          type: "public-key",
          alg: -7,
        },
      ],
    };
    console.log(publicKey);
    navigator.credentials.create({ publicKey }).then((credInfo) => {
      window.credInfo = credInfo;
      const response = credInfo.response;
      console.log(credInfo);
      const credData = {
        desc: state.description,
	fixed_desc: props.fixedDescription,
        rp_id: state.rp,
        user: props.username,
        publicKey: {
          id: credInfo.id,
          rawId: arrayBufferToBase64(credInfo.rawId),
          type: "public-key",
          response: {
            attestationObject: arrayBufferToBase64(response.attestationObject),
            clientDataJSON: arrayBufferToBase64(response.clientDataJSON),
          },
        },
      };
      console.log(credData);
      const data = JSON.stringify(credData);
      const credentialData = window.btoa(data);
      const signature = arrayBufferToBase64(
        CryptJsWordArrayToUint8Array(this.hmacSign(credentialData))
      );
      const nonce = Base64.stringify(this.props.nonce);
      this.setState({
        encodedCredential: { credentialData, signature, nonce },
        phase: "finalize",
      });
    });
  }
  render() {
    switch (this.state.phase) {
      case "finalize":
        try {
          const receiverUrl = new URL(this.props.receiver);
          const topicId = Hex.stringify(this.hmacSign("topic")).slice(0, 16);
          return (
            <CredentialDistMqtt
              receiver={receiverUrl.toString()}
              autoSend={true}
              topic={"wui_credential/" + topicId}
              credentialData={this.state.encodedCredential.credentialData}
              signature={this.state.encodedCredential.signature}
              nonce={this.state.encodedCredential.nonce}
            />
          );
        } catch (e) {
          return (
            <CredentialDistEmail
              receiver={this.props.receiver}
              credentialData={this.state.encodedCredential.credentialData}
              signature={this.state.encodedCredential.signature}
              nonce={this.state.encodedCredential.nonce}
            />
          );
        }
      case "challenge":
        return (
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control
                onChange={(event) => {
                  event.preventDefault();
                  this.setState({ description: event.target.value });
                }}
                type="text"
                placeholder="Authenticator name"
              />
              <Form.Text className="text-muted">
                The name that will be attached to this authenticator
              </Form.Text>
            </Form.Group>
            <fieldset disabled={this.state.description.length < 4}>
              <Button
                onClick={this.fidoChallenge}
                variant="primary"
                type="submit"
              >
                Submit
              </Button>
            </fieldset>
          </Form>
        );
      default:
        return <div>Default why??</div>;
    }
  }
}

class CredentialDistEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url:
        "mailto:" +
        props.receiver +
        "?subject=Fidocredential&body=" +
        props.credentialData +
        "." +
        props.signature +
        "." +
        props.nonce,
    };
  }

  render() {
    return (
      <Alert variant="success">
        Your authenticator has been registered successfully{" "}
        <Alert.Link href={this.state.url}>Email credential data</Alert.Link>
      </Alert>
    );
  }
}

class CredentialDistMqtt extends React.Component {
  constructor(props) {
    super(props);
    this.errorHandler = this.errorHandler.bind(this);
    this.transmitCredential = this.transmitCredential.bind(this);
    this.state = {
      error: null,
      publish: null,
      complete: false,
      transmitting: false,
      reconnectCount: 0,
    };
  }

  componentDidMount() {
    const props = this.props;
    const client = mqtt.connect(props.receiver);
    client.on("connect", () => {
      client.subscribe(props.topic, (err) =>
        this.errorHandler(err, () => {
          this.setState({
            publish: (message) =>
              client.publish(
                props.topic,
                message,
                { qos: 1, retain: true },
                (err) =>
                  this.errorHandler(err, () => {
                    this.setState({ complete: true });
                  })
              ),
          })
        })
      );
    });
    client.on("error", this.errorHandler);
    client.on("reconnect", () => {
      if (this.state.reconnectCount > 3) {
        this.errorHandler("Failed to connect to MQTT");
        client.end();
      }
      this.setState((state) => {
        return { reconnectCount: state.reconnectCount + 1 };
      });
    });
  }

  errorHandler(err, onSucess) {
    if (err) {
      this.setState({ error: err });
    } else {
      if (onSucess) {
        onSucess();
      }
    }
  }

  transmitCredential(e) {
    if (e) {
      e.preventDefault();
    }
    this.setState({ transmitting: true });
    this.state.publish(
      [this.props.credentialData, this.props.signature, this.props.nonce].join(
        "."
      )
    );
  }

  render() {
    if (this.state.error) {
      return (
        <Alert variant="error">An error has occured: {this.state.error}</Alert>
      );
    }
    if (this.state.complete) {
      return (
        <Alert variant="success">
          Transmitted credential successfully! Topic #{this.props.topic}
        </Alert>
      );
    }
    if (this.state.transmitting) {
      return <Spinner animation="grow" />;
    }

    if (this.state.publish) {
      return (
        <Alert variant="success">
          Connected to MQTT, ready to transmit your credential
          <Button
            onClick={this.transmitCredential}
            variant="primary"
            type="submit"
          >
            Send credential
          </Button>
        </Alert>
      );
    }
    return <Alert variant="info">connecting to MQTT</Alert>;
  }
}

export default App;
